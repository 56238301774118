.burger-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;

  .btn-close {
    opacity: 0;
    top: 0;

    svg {
      width: 40px;
      height: 40px;
      margin-top: -5px;

      #Group {
        fill: white;
      }
    }
  }

  .hamburger {
    width: 40px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    z-index: 99;
    position: relative;
    outline: none;
    cursor: pointer;
    margin-top: 40px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;

    @media (min-width: 1024px) {
      margin-top: 50px;
    }

    span {
      position: absolute;
      height: auto;
      width: 100%;
      background: #fff;
      left: 0;

      &.burgerpage {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
        left: 0;
        &.active {
          background: $colorGreen;
        }
      }
    }
  }
}

.navigation-container {
  position: relative;
  display: block;
  .navigation {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    width: inherit;
    max-width: 1920px;
    background-color: $colorBlue;
    height: 80px;
    margin: 0 auto;

    @media (min-width: 1024px) {
      height: 100px;
    }

    .menu {
      background-color: $colorBlue;
      opacity: 0;
      pointer-events: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style: none;
      overflow: hidden;
      z-index: 10;
      margin-top: 80px;

      @media (min-width: 1024px) {
        margin-top: 100px;
      }

      li.menu-item {
        a {
          color: #ffffff;
          text-align: center;
          font-family: 'Flama';
          font-size: 20px;
          line-height: 34px;
          font-weight: 300;
          font-style: normal;
          text-decoration: none;

          &:hover {
            color: $colorGreen;
          }
        }
      }

      li.submenu-item {
        a {
          color: #ffffff;
          text-align: center;
          font-family: 'Flama';
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          text-decoration: none;
        }
      }

      &.open {
        margin-top: 80px;
        animation: 0.5s menuToggleIn;
        height: calc(100vh - 80px);
        opacity: 1;
        pointer-events: initial;
      }

      &.closed {
        animation: 0.5s menuToggleOut;
        height: 0vh;
        opacity: 0;
        pointer-events: none;
      }

      .sociallinks {
        margin-top: 30px;
        font-size: 20px;

        a {
          color: white;
          text-decoration: none;
        }
      }

      .menu-divider {
        height: 4px;
        width: 250px;
        background-color: #ffffff;
        margin: 50px 0 0;
      }
    }

    &:before {
      content: '';
      width: 0px;
      height: 0px;
      -webkit-transform: rotate(360deg);
      border-style: solid;
      border-width: 50px 50px 0 0;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      z-index: 99;
    }
  }
}

.languages {
  margin-top: 30px;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:first-child {
        a {
          &:after {
            content: '/';
            color: #fff;
            font-family: 'Flama';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin: 0 4px 0 2px;
          }
        }
      }

      a {
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        font-family: 'Flama';
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        &:hover {
          color: $colorGreen;
        }
      }
    }
  }
}

.social-media {
  margin-top: 2rem;
  a:first-child {
    margin-right: 10px;
  }
}

.page-name {
  position: absolute;
  left: -150px;
  text-align: right;
  width: 125px;
  top: 50%;
  -webkit-transform: translate(0, -46%);
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  font-family: 'Flama';
  visibility: hidden;

  @media (min-width: 1024px) {
    @include fontresizer(16px, 18px);
    left: -14.2vw;
    width: 11.7vw;
    visibility: visible;
  }

  @media (min-width: 1920px) {
    @include fontresizer(16px, 18px);
    left: -245px;
    width: 210px;
  }
}

.no-scroll .page-name {
  display: none;
}

@keyframes menuToggleIn {
  0% {
    height: 0vh;
  }

  100% {
    height: calc( 100vh - 80px );
  }
}

@keyframes menuToggleOut {
  0% {
    height: calc(100vh - 80px);
    opacity: 1;
  }

  100% {
    height: -20vh;
    opacity: 1;
  }
}
