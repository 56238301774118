.news-detail {
  margin-top: 2rem;

  h1 {
    line-height: 1.25;
    margin-bottom: 2rem;

    @media (min-width: 1024px) {
      margin-bottom: 4rem;
    }
  }

  article {
    margin-top: 2rem;

    @media (min-width: 1024px) {
      margin-top: 4rem;
    }
  }
}
