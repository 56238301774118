.contact {
  background: $colorGray;
  text-align: center;
  padding: 20px 0;

  article div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  img {
    width: 60px;
    margin-right: 15px;

    @media (min-width: 768px) {
      width: 80px;
    }
  }

  p {
    margin-bottom: 0;
  }

  h3 {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  @media(min-width: 768px) {
    article {
      display: flex;
      justify-content: space-between;
      max-width: 500px;
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  @media (min-width: 1024px) {
    article {
      max-width: 650px;
    }
  }
}
