body {
  font-family: 'Flama', 'Times New Roman', serif;
  color: $colorBlue;
  line-height: 1.5;
  font-weight: lighter;

  @media (min-width: 1024px) {
    font-size: 18px;
  }
}

h1,
h2,
p {
    margin: 0;
    padding: 0;
}

h1 {
  font-weight: 100;
  font-size: 1.625rem;

  @media (min-width: 1024px) {
    font-size: 2.5rem;
    line-height: 1.25;
  }
}

.news h1,
.about h1 {
  font-size: 1.25rem;
  margin-top: 1rem;
}

h2 {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

h3 {
  font-weight: 100;

  @media (min-width: 768px) {
    font-size: 1.77rem;
  }
}

h4 {
  font-weight: 100;

  @media (min-width: 768px) {
    font-size: 1.22rem;
  }
}

.intro-text strong {
  font-size: 1.33rem;
}

p,
strong {
  margin-bottom: 1.5rem;
}

a {
  color: $colorBlue;
  word-break: break-word;
}

strong {
  font-weight: bold;
}
