.default {
  hr {
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      margin-top: 3.5rem;
    }
  }
}
