.footer {
  background: $colorBlue;
  color: white;
  font-size: 0.75rem;

  .logo-container {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 2rem;
    }
  }

  .footer-infos {
    display: flex;
    flex-wrap: wrap;

    article {
      width: 160px;
      padding-right: 1rem;

      @media (min-width: 768px) {
        padding-bottom: 2rem;
      }

      @media (min-width: 1024px) {
        width: 150px;
        padding-right: 2rem;
      }
    }
  }

  strong {
    text-transform: uppercase;
  }

  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .footer-end {
    text-align: center;

    @media (min-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      ul,
      .copyright {
        margin: 0;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    margin-top: 3rem;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 10px;
      padding-right: 10px;

      a {
        color: white;
        text-decoration: none;

        &:after {
          content: '';
          border-right: 1px solid #fff;
          margin-left: 10px;
          margin-top: 4px;
          position: absolute;
          height: 12px;
        }
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;

        a:after {
          content: none;
        }
      }
    }
  }

  .copyright {
    margin-top: 2rem;
  }

  @media (min-width: 1024px) {
    .content-container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
