.about {
  hr {
    margin-top: 1rem;
    margin-bottom: 1.25rem;
  }

  h4 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .about-container img {
    width: 200px;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 0;
  }

  h5 {
    font-size: 1rem;
    font-weight: 100;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .team-group {
    margin-top: 3rem;
  }

  .team-founder {
    @media (min-width: 768px) {
      margin-top: 3rem;
    }
  }

  .team-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    article {
      width: 250px;
    }
  }

  @media (min-width: 1024px) {
    .intro-text {
      max-width: 700px;
    }

    .about-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .team-founder {
        width: 37%;
        margin-right: 8%;
      }

      .team-group {
        width: 55%;

        article {
          width: 50%;
        }
      }
    }
  }
}
