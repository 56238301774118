.dienstleistung .modal {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f5f5f5;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 100;
  display: none;

  @media (min-width: 1024px) {
    top: 100px;
  }

  &.active {
    display: block;
  }

  .close {
    position: absolute;
    top: -60px;
    right: 20px;
    width: 40px;
    padding: 0;
    background: $colorBlue;
    color: $colorGreen;
    border: 0;

    svg {
      width: 40px;
      height: 40px;

      #Group {
        fill: white;
      }
    }

    &:focus,
    &:hover {
      cursor: pointer;
      outline: none;

      svg #Group {
        fill: $colorGreen;
      }
    }

    @media (min-width: 768px) {
      top: 15px;
      right: 15px;
      background: none;

      svg #Group {
        fill: $colorBlue;
      }
    }

    @media (min-width: 1024px) {
      top: 40px;
      right: 40px;
    }
  }

  hr {
    border-top: 5px solid white;
  }

  header {
    background: $colorBlue;
    color: white;
  }

  .content {
    background: #f5f5f5;

    h2 {
      text-transform: none;
    }
  }

  .wrapper {
    overflow: scroll;
    height: calc(100vh - 80px);

    @media (min-width: 768px) {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 85%;
      height: auto;
      max-width: 648px;
      overflow: hidden;
    }

    @media (min-width: 1024px) {
      max-width: 1120px;
    }
  }

  @media (min-width: 768px) {
    background: rgba(255, 255, 255, 0.9);

    .content-layout {
      display: flex;
      > * {
        width: 50%;
      }
    }

    h4 {
      margin-top: 0;
    }

    .content {
      padding-top: 7.2rem;
    }
  }

  @media (min-width: 1024px) {
    h4 {
      font-size: 1.77rem;
    }

    .content-layout {
      header {
        width: 30%;
      }

      .content {
        padding-top: 10.4rem;
        width: 70%;
      }
    }
  }
}
