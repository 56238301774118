.news {
  hr {
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: auto;

    @media (min-width: 768px) {
      max-width: 240px;
    }
  }

  a {
    text-decoration: none;
  }

  strong {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: 1.375rem;
    font-weight: 100;
    margin-top: 0;
    text-transform: none;
  }

  p {
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      margin-bottom: 2rem;
    }
  }

  article {
    margin-bottom: 3rem;
    max-width: 567px;

    @media (min-width: 768px) {
      margin-bottom: 5rem;
    }

    @media (min-width: 1024px) {
      max-width: none;

      a {
        display: flex;
        max-width: none;
      }

      .image-container {
        margin-right: 4rem;
      }

      strong {
        margin-top: 0;
      }
    }
  }

  .image-container img {
    @media (min-width: 768px) {
      max-width: 240px;
    }
  }
}

.more {
  position: relative;
  background: $colorBlue;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 4px 10px 5px 6px;
  border: 0;
  font-size: 1rem;
  text-decoration: none;

  &:focus,
  &:active,
  &:hover {
    background: $colorGreen;
    cursor: pointer;
    outline: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 6px;
    top: -6px;
    right: -10px;
    border-top: 8px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    transform: rotate(-135deg);
  }
}
