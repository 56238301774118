.dienstleistung {
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    .hero .content-container {
      position: absolute;
      max-width: 230px;
      bottom: 10%;
      right: 10%;
      top: auto;
      padding-top: 20px;

      h1 {
        color: white;
      }
    }
  }

  @media (min-width: 1024px) {
    .hero .content-container {
      max-width: 330px;
    }
  }

  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}
