.referenzen {
  h4 {
    font-size: 1.25rem;
  }

  @media (min-width: 768px) {
    .hero .content-container {
      right: 18%;
    }
  }
}
