img {
  max-width: 100%;
}

hr {
  border-bottom: 0;
  border-top: 5px solid $colorBlue;
  border-style: solid;
  width: 100%;
}

.content-container {
  padding: 20px;

  @media (min-width: 768px) {
    max-width: 570px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: 1024px) {
    max-width: 980px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.tripple-element {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  article {
    padding: 1rem 0;

    @media (min-width: 768px) {
      max-width: 250px;
    }

    @media (min-width: 1024px) {
      max-width: 270px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: 120px;
  }

  hr {
    margin-bottom: 1.5rem;
  }
}

.hero {
  img {
    width: 100%;
    display: block;
  }

  @media (min-width: 768px) {
    position: relative;

    h1 {
      font-weight: 200;
    }

    .content-container {
      position: absolute;
      max-width: 250px;
      top: 10%;
      right: 10%;
      padding-top: 20px;

      @media (min-width: 1024px) {
        max-width: 390px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .intro-text {
    max-width: 840px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
}

.content-wrapper {
  position: relative;

  @media (min-width: 1024px) {
    .content-container {
      padding: 80px;
    }
  }
}

.side-element-blue,
.side-element-green {
  position: absolute;
  width: 60px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.side-element-blue {
  right: 0;
  bottom: 12%;
}

.side-element-green {
  left: 0;
  top: 3%;
}

.main-intro {
  background: $colorGray;
  margin-bottom: 10px;

  p {
    font-weight: 200;
    margin-bottom: 0;
    font-size: 1.1rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }

    @media (min-width: 1024px) {
      font-size: 2rem;
    }
  }

}

@media (min-width: 1024px) {
  .hero img {
    margin-top: -35px;
  }
}
