.image-container {
  position: relative;
}

.overlay-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }

  @media (min-width: 1350px) {
    top: 2%;
  }

  .overlay {
    position: absolute;

    a {
      position: relative;
      display: inline;
      font-weight: bold;
      color: white;
      background: $colorBlue;
      text-decoration: none;
      border-radius: 100%;
      z-index: 3;
      height: 22px;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;

      &:hover {
        //background: $colorGreen;
        cursor: default;
      }
    }

    strong {
      position: absolute;
      bottom: 45px;
      left: 10px;
      word-break: normal;
      white-space: nowrap;
      background: $colorBlue;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 1.2;
      font-weight: bold;
      margin-bottom: 0;
      clip-path: polygon(calc(100% - 8px) 0,100% calc(1% + 8px),100% 100%,0 100%,0 0);
      z-index: 5;
      font-size: 16px;

      padding: 0;
      max-width: 0;
      overflow: hidden;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      background: $colorBlue;
      width: 2px;
      height: 46px;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 2;

      max-height: 0;
    }
  }

  .overlay:nth-of-type(1) {
    right: 50.5%;
    top: 26.5%;
  }

  .overlay:nth-of-type(2) {
    right: 47.75%;
    top: 26%;
  }

  .overlay:nth-of-type(3) {
    right: 37%;
    top: 18.5%;
  }

  .overlay:nth-of-type(4) {
    right: 31.5%;
    top: 21.5%;
  }

  .overlay:nth-of-type(5) {
    right: 29%;
    top: 23%;
  }

  .overlay:nth-of-type(6) {
    right: 24.5%;
    top: 29%;

    strong {
      white-space: normal;
    }

    span {
      min-width: 150px;
      display: block;
    }
  }

  .overlay:nth-of-type(7) {
    right: 16.5%;
    top: 27%;

    strong {
      white-space: normal;
    }
  }

  //.overlay:hover,
  .overlay.active {
    cursor: pointer;
    &::after {
      max-height: 50px;
      transition: max-height 0.5s ease;
    }

    > strong {
      padding: 3px 10px 3px 3px;
      max-width: 350px;
      transition: max-width 0.5s ease, padding 0s ease;
      transition-delay: 0.4s;
    }
  }
}
