$colorBlue: #0f1d3f;
$colorGreen: rgb(137,195,86);
$colorPink: rgb(227,37,73);
$colorGold: rgb(255,204,52);
$colorRed: rgb(227,37,73);
$colorGray: #f0f0f0;

@font-face {
    font-family: "Flama";
    src: url(../fonts/flama/Flama.woff) format("woff"), url(../fonts/flama/Flama.woff2) format("woff2");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "Flama";
    src: url(../fonts/flama/FlamaLight.woff) format("woff"), url(../fonts/flama/FlamaLight.woff2) format("woff2");
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: "Flama";
    src: url(../fonts/flama/FlamaBook.woff) format("woff"), url(../fonts/flama/FlamaBook.woff2) format("woff2");
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: "Flama";
    src: url(../fonts/flama/Flama-Bold.woff) format("woff"), url(../fonts/flama/Flama-Bold.woff2) format("woff2");
    font-style: normal;
    font-weight: 600;
}

@font-face {
  font-family: 'cetonis';
  src: url('../fonts/cetonis/cetonis.eot');
  src: url('../fonts/cetonis/cetonis.eot?euv2i4#iefix') format('embedded-opentype'),
  url('../fonts/cetonis/cetonis.ttf?euv2i4') format('truetype'),
  url('../fonts/cetonis/cetonis.woff?euv2i4') format('woff'),
  url('../fonts/cetonis/cetonis.svg?euv2i4#cetonis') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  body {
    padding: 80px 0 0 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    position: relative;

    @media (min-width: 1024px) {
      padding: 100px 0 0 0;
    }
  }

  &.no-scroll {
    overflow-y: hidden;
    body {
      overflow-y: hidden;
    }

    .burger-container {
      .hamburger {
        font-weight: bold;
        span {
          &.burgerpage {
            opacity: 0;
            background: rgba(0, 0, 0, 0);
          }
          &.btn-close {
            opacity: 1;
            background: rgba(0, 0, 0, 0);
          }
        }
      }
    }
  }
}

.logo {
  fill: #fff;
  stroke: #fff;
  position: absolute;
  top: 18px;
  left: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 100;

  img {
    height: 45px;
  }

  @media (min-width: 1024px) {
    top: 23px;

    img {
      height: 55px;
    }
  }

  span {
    margin-left: 15px;
    color: #fff;
    text-decoration: none;
    border-bottom: none;
    color: #fff;
    line-height: 17px;
  }
}
