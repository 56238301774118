// Breakpoint variables
$max-breakpoint: 1920;

// Media query variables
$wide-screen : "(min-width: #{$max-breakpoint}px)"; 


@mixin fontresizer($fontsize, $lineheigt) {
  font-size: get-vw($fontsize);
  line-height: get-vw($lineheigt);
  @media #{$wide-screen} {
    font-size: $fontsize;
    line-height: $lineheigt;
  }
}

// Function
@function get-vw($target) {
  $vw-context: ($max-breakpoint*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}